import { useCallback } from 'react'
import { AuthApiError } from '@supabase/supabase-js'

import { supabaseComponentClient } from '@/plugins/supabase'

import { useAuthContext } from './useAuthContext'

const getSupabaseCookieName = () => {
  const allCookies = document.cookie.split('; ')
  const allCookieNames = allCookies.map(cookie => cookie.split('=')[0])
  const supabaseCookieName = allCookieNames.find(
    cookieName =>
      cookieName.startsWith('sb-') && cookieName.endsWith('-auth-token'),
  )

  return supabaseCookieName?.split('=')[0]
}

const removeSupabaseCookieAndToken = () => {
  const cookieName = getSupabaseCookieName()
  if (cookieName) {
    document.cookie = `${cookieName}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/`
    window.localStorage.removeItem(cookieName)
  }
}

export const useLogOut = () => {
  const { setIsSigningOut, cleanupOnSignedOut } = useAuthContext()

  return useCallback(async (onSignedOut?: () => void) => {
    setIsSigningOut(true)

    try {
      const { error } = await supabaseComponentClient.auth.signOut()
      if (error) {
        const isAuthApiError = error instanceof AuthApiError
        if (isAuthApiError && error.status === 403) {
          removeSupabaseCookieAndToken()
        }
      }

      cleanupOnSignedOut()
      onSignedOut?.()
    } catch (error) {
      console.error('Error during sign out', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
