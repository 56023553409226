import React, { useMemo } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { UITree } from '@/components/ui'
import { TreeItems, TreeItem } from '@/components/ui'
import { ComputerIcon, CheckListIcon, FilesIcon } from '@/components/ui/icons'
import { NavSection } from '@/components/shared'
import { useGetWorkspaceNavigation } from '@/hooks/queries'
import { BellIcon } from '@heroicons/react/24/outline'
import { useFeatures } from '@/hooks/useFeatures'

interface WorkspaceNavigationProps {
  isCompact: boolean
}

const initialItems: TreeItems = [
  {
    id: 'playbooks',
    label: 'Playbooks',
    type: 'item',
    href: '/playbooks',
    Icon: CheckListIcon,
    children: [],
  },
]

const WorkspaceNavigation = ({ isCompact }: WorkspaceNavigationProps) => {
  const pathname = usePathname()
  const { isAlertsEnabled } = useFeatures()

  const { data: workspaceNavigation, isLoading: isFirstLoading } =
    useGetWorkspaceNavigation()

  const items = useMemo(() => {
    const alertsItem: TreeItem = {
      id: 'alerts',
      label: 'Alerts',
      type: 'item',
      href: '/alerts',
      Icon: BellIcon,
      children: [],
    }

    const baseItems = [...initialItems]
    if (isAlertsEnabled) {
      baseItems.push(alertsItem)
    }

    if (!workspaceNavigation) {
      return baseItems
    }

    const allDocumentsItem: TreeItem = {
      id: workspaceNavigation.slug,
      href: '/all-documents',
      Icon: FilesIcon,
      label: workspaceNavigation.label,
      type: 'group',
      children: workspaceNavigation.children.length
        ? workspaceNavigation.children.map(
            view =>
              ({
                id: `views/${view.id}`,
                href: `/views/${view.id}`,
                label: view.label,
                type: 'item',
                children: [],
              }) as TreeItem,
          )
        : [],
    }

    const newNavigation = [allDocumentsItem, ...baseItems]

    return newNavigation
  }, [workspaceNavigation, isAlertsEnabled])

  if (isFirstLoading) return null

  return (
    <NavSection
      title="Workspace"
      isCompact={isCompact}
      CompactIcon={ComputerIcon}
    >
      <UITree
        defaultItems={items}
        currentPath={pathname}
        LinkComponent={Link}
        isCompact={isCompact}
        collapsible
        indentationWidth={30}
      />
    </NavSection>
  )
}

export default WorkspaceNavigation
