import { Link } from '@nextui-org/link'
import React, { memo } from 'react'

const SupportLink = memo(() => (
  <Link
    isExternal
    size="sm"
    className="ui font-bold text-inherit"
    href="mailto:support@docsum.ai"
  >
    support@docsum.ai
  </Link>
))

SupportLink.displayName = 'SupportLink'

export default SupportLink
