import { DocsumDocumentType } from '@/models/api'

import BaseApi from './base'
import { apiClient, type ApiRequestConfig, getHeaders } from './client'

export class DocumentTypesApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = 'document-type' // TODO: should be in plural, need to fix api
  }

  async getAll({ signal, ...configProps }: ApiRequestConfig = {}) {
    return apiClient.getAll<DocsumDocumentType[]>(this.resourcePath, {
      signal,
      ...getHeaders(configProps),
    })
  }
}

const documentTypesApi = new DocumentTypesApi()

export default documentTypesApi
