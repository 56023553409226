import { OutputFileEntry } from '@uploadcare/blocks'

export const commonLocaleDefinitionOverride = {
  en: {
    'upload-file': 'Upload file',
    'upload-files': 'Upload files',
    'choose-file': 'Click to choose file or drag and drop',
    'choose-files': 'Click to choose files or drag and drop',
    'drop-files-here': 'Click to choose file(s) or drag and drop here',
    'no-files': 'No files selected',
    'files-count-allowed': 'Only {{count}} {{plural:photo(count)}} allowed',
    'files-max-size-limit-error':
      'File is too big. Max file size is {{maxFileSize}}.',
    'header-uploading': 'Uploading {{count}} {{plural:photo(count)}}',
    'header-succeed': '{{count}} {{plural:photo(count)}} uploaded',
    'header-total': '{{count}} {{plural:photo(count)}} selected',
    photo__one: 'file',
    photo__many: 'files',
    photo__other: 'files',
  },
}

export type UploadedFileEntry = OutputFileEntry<'success'>

const UploadHelperTextClassName = 'helper-text-wrapper'

const DropAreaWrapperSelector = 'lr-start-from .content-wrapper'

export const injectValidationsTextInDropArea = (
  formatText: string,
  maxSizeText: string,
) => {
  const contextWrapperElement = document.querySelector(DropAreaWrapperSelector)

  if (contextWrapperElement) {
    const newDivWrapper = document.createElement('div')
    newDivWrapper.classList.add(UploadHelperTextClassName)

    const newAllowedFormatsSpan = document.createElement('span')
    newAllowedFormatsSpan.classList.add('font-medium', 'uppercase')
    newAllowedFormatsSpan.textContent = formatText

    const newSeparatorSpan = document.createElement('span')
    newSeparatorSpan.textContent = '·'
    newSeparatorSpan.setAttribute('aria-hidden', 'true')

    const newMaxSizeSpan = document.createElement('span')
    newMaxSizeSpan.textContent = 'Max size'

    const newMaxSizeValueSpan = document.createElement('span')
    newMaxSizeValueSpan.classList.add('font-medium')
    newMaxSizeValueSpan.textContent = ` ${maxSizeText}MB`

    newMaxSizeSpan.appendChild(newMaxSizeValueSpan)
    newDivWrapper.appendChild(newAllowedFormatsSpan)
    newDivWrapper.appendChild(newSeparatorSpan)
    newDivWrapper.appendChild(newMaxSizeSpan)
    contextWrapperElement.appendChild(newDivWrapper)
  }
}

export const removeValidationsTextFromDropArea = () => {
  const contextWrapperElement = document.querySelector(DropAreaWrapperSelector)

  if (contextWrapperElement) {
    const helperTextWrapper = contextWrapperElement.querySelector(
      `.${UploadHelperTextClassName}`,
    )
    if (helperTextWrapper) {
      contextWrapperElement.removeChild(helperTextWrapper)
    }
  }
}
