import React from 'react'

import { ArrowLeftIcon, UIButton, UITooltip } from '@/components/ui'
import { useSidebar } from '@/hooks'

export const SidebarCollapseButton = () => {
  const { canHide, setIsSidebarCollapsed, setIsSidebarHidden } = useSidebar()

  const hideSidebar = () => {
    setIsSidebarCollapsed(true)
    canHide && setIsSidebarHidden(true)
  }

  return (
    <UITooltip
      content={`${canHide ? 'Hide' : 'Collapse'} sidebar`}
      placement="right"
    >
      <UIButton isIconOnly size="sm" variant="light" onPress={hideSidebar}>
        <ArrowLeftIcon className="h-5 w-5" />
      </UIButton>
    </UITooltip>
  )
}
