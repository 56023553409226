import { QueryClient } from '@tanstack/react-query'

const DEFAULT_STALE_TIME_2_MIN_IN_MS = 2 * 60 * 1000

export const queryClientDefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    staleTime: DEFAULT_STALE_TIME_2_MIN_IN_MS,
  },
}

export const getSsrQueryClient = () => {
  return new QueryClient({
    defaultOptions: queryClientDefaultOptions,
  })
}
