export const QKEY = {
  ACCOUNTS: {
    getAll: ['accounts'] as const,
    removeMember: ['remove-member'] as const,
    invite: ['accounts-invite'] as const,
    switch: ['accounts-switch'] as const,
    patch: ['accounts-patch'] as const,
    getUsers: (accountId: string | undefined) =>
      ['accounts-get-users', accountId] as const,
  },
  ACCOUNTS_INVITE: ['accounts-invite'],
  CK_TOKEN: ['ck-token'] as const,
  CLAUSES: ['clauses'] as const,
  DOCUMENT: (documentId: string | undefined) =>
    ['document', documentId] as const,
  DOCUMENTS: {
    get: (documentId?: string | undefined) =>
      ['document-by-id', documentId] as const,
    getAll: ['documents'] as const,
    batchUpload: ['documents-upload-batch'] as const,
    runInfoExtraction: ['documents-run-info-extraction'] as const,
    exportToCsv: ['documents-export-to-csv'] as const,
  },
  DOCUMENTS_CONVERT_DOCX_TO_HTML: ['documents-convert-docx-to-html'] as const,
  DOCUMENTS_CONVERT_HTML_TO_DOCX: ['documents-convert-html-to-docx'] as const,
  DOCUMENTS_STATS: ['documents-stats'] as const,
  DOCUMENT_EDITOR_DATA: (
    documentId: string | undefined,
    versionNumber: number | undefined,
  ) => ['document-version-editor-data', documentId, versionNumber] as const,
  DOCUMENT_INFO_FIELDS: {
    getAll: ['document-info-fields-get-all'] as const,
    getAllValuesByDocument: (
      searchParams?: Record<string, unknown> | undefined,
    ) => ['document-info-fields-get-values', searchParams] as const,
    createFieldValue: ['document-version-create-field-value'] as const,
    updateFieldValue: ['document-version-update-field-value'] as const,
  },
  DOCUMENT_REVISIONS: {
    getAll: (documentId: string | undefined) =>
      ['document-revisions-get-all', documentId] as const,
  },
  DOCUMENT_STATS: (documentId: string | undefined) =>
    ['document-stats', documentId] as const,
  DOCUMENT_TERMS: (documentId: string | undefined) =>
    ['document-terms', documentId] as const,
  DOCUMENT_TYPE: (documentId: string | undefined) =>
    ['document-type', documentId] as const,
  DOCUMENT_TYPES: ['document-type'] as const,
  DOCUMENT_VERSIONS: {
    getByNumber: (
      documentId?: string | undefined,
      versionNumber?: number | undefined,
    ) => ['document-version-get-by-number', documentId, versionNumber] as const,
    getAll: (documentId: string | undefined) =>
      ['document-version-get-all', documentId] as const,
    update: ['document-version-update'] as const,
    getDownloadUrls: (
      documentId?: string | undefined,
      versionNumber?: number | undefined,
    ) =>
      [
        'document-version-get-download-urls',
        documentId,
        versionNumber,
      ] as const,
    getFieldValues: (versionId?: string | undefined) =>
      ['document-version-get-field-values', versionId] as const,
    generateHtml: ['document-version-generate-html'] as const,
    analyzeFlags: ['document-version-analyze-flags'] as const,
  },
  FAVORITE_VIEW_FOLDERS: {
    create: ['favorite-view-folder-create'] as const,
    patch: ['favorite-view-folder-patch'] as const,
    delete: ['favorite-view-folder-delete'] as const,
  },
  FEATURES: ['features-get-all'] as const,
  FILTERS: {
    getAllSupported: ['filters-get-all-supported'] as const,
  },
  IDENTIFIED_CLAUSES: (
    documentId: string | undefined,
    versionNumber: number | undefined,
  ) => ['file', documentId, versionNumber] as const,
  INTEGRATIONS: {
    getParagonToken: ['integrations-get-paragon-token'] as const,
  },
  PARAGON_API: {
    getIntegrations: ['paragon-api-get-integrations'] as const,
  },
  PLAYBOOK: (t: string | undefined) => ['playbooks', t] as const,
  PLAYBOOKS: ['playbooks'] as const,
  PRODUCTS: ['products'] as const,
  QUESTIONS: (
    documentId: string | undefined,
    documentVersion: number | undefined,
  ) => ['questions', documentId, documentVersion] as const,
  REDLINE_ANALYSIS_REPORT: (documentVersionId: string | undefined) =>
    ['redline-analysis-report', documentVersionId] as const,
  REVIEW: (documentId: string | undefined) => ['review', documentId] as const,
  SUGGESTED_QUESTIONS: (documentType: string | null | undefined) =>
    ['suggested-questions', documentType] as const,
  SUMMARY: (documentId: string | undefined) => ['summary', documentId] as const,
  USER: ['user'] as const,
  VIEWS_NAVIGATION: {
    patchFavoritesNavigation: ['navigation-favorites-patch'] as const,
    getFavoritesNavigation: ['navigation-favorites'] as const,
    getWorkspaceNavigation: ['navigation-workspace'] as const,
  },
  VIEWS: {
    get: (viewIdOrSlug: string | undefined) =>
      ['views-get-by-id-slug', viewIdOrSlug] as const,
    create: ['views-create'] as const,
    patch: ['views-patch'] as const,
    archive: ['views-archive'] as const,
  },
} as const
