export const API_URL =
  process.env.NEXT_PUBLIC_API_URL ??
  (() => {
    throw new Error('Missing API url')
  })()

export const DEFAULT_PAGINATION_OPTIONS = [
  '10',
  '20',
  '50',
  '100',
  '500',
  '1000',
] as const

export const DEFAULT_PAGINATION_SIZE = +DEFAULT_PAGINATION_OPTIONS[1]

export const DEFAULT_STATIC_DATA_CACHE_20_MIN_IN_MS = 20 * 60 * 1000

export const DEFAULT_POLLING_DATA_REFETCH_INTERVAL_MS = 5000

// TODO: should be in plural
export const API_DOCUMENTS_ROUTE_PATH = 'document'

export const unknownGlobalErrorMessage =
  'Oops! Something went wrong. Please try again or contact support (support@docsum.ai).'

export const PARAGON_API_URL =
  process.env.NEXT_PUBLIC_PARAGON_API_URL ??
  (() => {
    console.error('Missing Paragon API url')
  })()

export const HEADER_X_TIMEZONE = 'X-Timezone'
