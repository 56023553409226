import {
  AccountPatchRequest,
  AccountRemoveMemberRequest,
  AccountSendInviteRequest,
  AccountSwitchResponse,
} from '@/models/api'
import { AccountUser } from '@/models/user'
import { AccountUserRoles } from '@/models/enums'

import BaseApi from './base'
import { apiClient } from './client'

export class AccountApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = 'account'
  }

  async removeMemberFromAccount({
    accountId,
    userId,
  }: AccountRemoveMemberRequest) {
    return apiClient.delete(`${this.resourcePath}/${accountId}/user`, userId)
  }

  async sendInvite({
    email,
    accountId,
    accountRoleId = AccountUserRoles.MEMBER,
  }: AccountSendInviteRequest) {
    return apiClient.post(`${this.resourcePath}/${accountId}/user`, {
      email,
      accountRoleId,
    })
  }

  async getAccountUsers(
    accountId: string,
    {
      signal,
    }: {
      signal?: AbortSignal
    } = {},
  ) {
    return apiClient.get<AccountUser[]>(
      this.resourcePath,
      `${accountId}/users`,
      {
        signal,
      },
    )
  }

  async switchAccount(toAccountId: string) {
    return apiClient.post<AccountSwitchResponse>(
      `${this.resourcePath}/${toAccountId}`,
    )
  }

  async patchAccount({ accountId, data }: AccountPatchRequest) {
    return apiClient.patch(this.resourcePath, accountId, data)
  }
}

const accountApi = new AccountApi()

export default accountApi
