import { Input, InputProps } from '@nextui-org/input'
import { forwardRef } from 'react'

import { cn } from '@/utils'

export type UIInputProps = InputProps
// export interface UIInputProps extends Omit<InputProps, 'size'> {
//   size?: InputProps['size'] | 'xs'
// }

const UIInput = forwardRef<HTMLInputElement | null, UIInputProps>(
  (props: UIInputProps, ref) => {
    // const isXs = size === 'xs'

    return (
      <Input
        ref={ref}
        // size={isXs ? 'sm' : size}
        {...props}
        classNames={{
          // input: cn(props.classNames?.input, isXs && 'text-tiny'),
          ...props.classNames,
          input: cn(['ui', 'placeholder-default-400', props.classNames?.input]),
          inputWrapper: cn(
            'px-3',
            'group-data-[focus-visible=true]:outline-0',
            'group-data-[focus-visible=true]:outline-offset-0',
            'group-data-[focus=true]:ring-1',
            'group-data-[focus=true]:ring-focus',
            'group-data-[focus-visible=true]:ring-1',
            'group-data-[focus-visible=true]:ring-focus',
            'group-data-[focus-visible=true]:ring-offset-0',
            props.classNames?.inputWrapper,
          ),
        }}
      />
    )
  },
)

UIInput.displayName = 'UIInput'

export default UIInput

// const UIInput = React.forwardRef((props, ref) => {
