import React, { useCallback, useMemo, useState } from 'react'
import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/modal'

import {
  UploadcareFileUploaderInline,
  UploadedFileEntry,
  useUploaderCtxProvider,
} from '@/components/shared/UploadcareFileUploader'
import { useSelectedAccount } from '@/hooks/useSelectedAccount'
import { useUploadDocumentFiles } from '@/hooks/mutations'
import { UIAlert, useToast } from '@/components/ui'
import { useUser } from '@/hooks/queries/useUser'
import { SupportLink } from '@/components/shared'

interface UploadFilesDialogProps {
  isOpen: boolean
  onChange: (isOpen: boolean) => void
  onClose(): void
}

const UploadFilesDialog = ({
  isOpen,
  onChange,
  onClose,
}: UploadFilesDialogProps) => {
  const [isUploading, setIsUploading] = useState(false)

  const { ctxProviderRef } = useUploaderCtxProvider()

  const selectedAccount = useSelectedAccount()

  const {
    mutateAsync: uploadDocumentFiles,
    isPending,
    error: uploadDocumentFilesError,
  } = useUploadDocumentFiles()

  const { data: user } = useUser()

  const { toast } = useToast()

  const metadata = useMemo(
    () => ({
      userId: user?.id || '',
      accountId: selectedAccount?.id || '',
    }),
    [user, selectedAccount],
  )

  const showFailedUploadToast = useCallback(
    (count: number) => {
      toast.error(
        count > 1 ? `${count} file uploads failed` : 'File upload failed',
      )
    },
    [toast],
  )

  const onFilesUploadedSuccessHandler = useCallback(
    async (successEntries: UploadedFileEntry[]) => {
      const files = successEntries.map(({ name, uuid, cdnUrl }) => ({
        originalName: name,
        externalId: uuid,
        url: cdnUrl,
        tags: [],
      }))

      if (!files.length) return

      const uploadResults = await uploadDocumentFiles({ files })
      if (uploadResults) {
        const {
          successCount: successDocumentsCount,
          failedCount: failedDocumentsCount,
        } = uploadResults

        if (successDocumentsCount) {
          toast.success(
            successDocumentsCount > 1
              ? `${successDocumentsCount} files uploaded`
              : 'File uploaded',
          )
        }

        if (failedDocumentsCount) {
          showFailedUploadToast(failedDocumentsCount)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showFailedUploadToast, uploadDocumentFiles],
  )

  const onFilesUploadedFailedHandler = useCallback(
    (count: number) => {
      showFailedUploadToast(count)
    },
    [showFailedUploadToast],
  )

  return (
    <Modal
      classNames={{
        closeButton: 'top-1',
      }}
      isOpen={isOpen}
      onOpenChange={onChange}
      size="xl"
    >
      <ModalContent className="max-w-full sm:max-w-fit sm:min-w-[500px]">
        <ModalHeader className="py-7">
          <div className="flex w-full flex-col items-center gap-1">
            <div className="text-2xl font-bold">Upload Document</div>
            <div className="text-small font-light text-default-500">
              Your contract data is secure and confidential
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="pb-7">
          <UploadcareFileUploaderInline
            metadata={metadata}
            isDisabled={isUploading || isPending}
            contextProviderRef={ctxProviderRef}
            onUploading={() => {
              setIsUploading(true)
            }}
            onFinished={() => {
              setIsUploading(false)
            }}
            onFilesUploadedSuccess={onFilesUploadedSuccessHandler}
            onFilesUploadedFailed={onFilesUploadedFailedHandler}
            onDone={onClose}
          />

          <div className="mt-4 text-center text-tiny text-default-400">
            (Clause analysis is unavailable for PDF)
          </div>

          {!!uploadDocumentFilesError && (
            <div className="pt-4 w-full">
              <UIAlert
                color="error"
                title="Upload Failed"
                message={
                  <span>
                    There was an error uploading the document(s). Please contact
                    support by emailing <SupportLink />
                  </span>
                }
              />
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default UploadFilesDialog
