import { cn } from '@/utils'
import {
  ChatBubbleBottomCenterTextIcon as DefaultIcon,
  XCircleIcon as ErrorIcon,
  InformationCircleIcon as InfoIcon,
  CheckCircleIcon as SuccessIcon,
  ExclamationTriangleIcon as WarnIcon,
} from '@heroicons/react/20/solid'

import { memo, ReactNode } from 'react'

type AlertColor = 'default' | 'info' | 'success' | 'warning' | 'error'

export interface IUIAlertProps {
  color?: AlertColor
  title?: string
  message?: string | ReactNode
  dense?: boolean
  hideIcon?: boolean
  className?: string
  children?: string | React.ReactNode
}

const IconMap = {
  success: SuccessIcon,
  error: ErrorIcon,
  warning: WarnIcon,
  info: InfoIcon,
  default: DefaultIcon,
}

const colorMap = {
  default: {
    background: 'bg-gray-100 dark:bg-black/50',
    icon: 'text-gray-400 dark:text-gray-400',
    title: 'font-medium text-gray-600 dark:text-white',
    message: 'text-gray-500 dark:text-white/80',
  },
  success: {
    background: 'bg-green-50 dark:bg-green-900/25',
    icon: 'text-green-400 dark:text-green-600',
    title: 'font-medium text-green-800 dark:text-green-300',
    message: 'text-green-700 dark:text-green-200',
  },
  // For normal Red error
  // error: {
  //   background: 'bg-red-50 dark:bg-red-900/20',
  //   icon: 'text-red-400 dark:text-red-600',
  //   title: 'font-medium text-red-800 dark:text-red-400/90',
  //   message: 'text-red-700 dark:text-red-200',
  // },
  error: {
    background: 'bg-danger-50 dark:bg-danger-200/30',
    icon: 'text-danger-400 dark:text-danger-500',
    title: 'font-medium text-danger-700 dark:text-danger-500',
    message: 'text-danger-600 dark:text-danger-700',
  },
  warning: {
    background: 'bg-yellow-50 dark:bg-yellow-900/20',
    icon: 'text-yellow-400 dark:text-yellow-600',
    title: 'font-medium text-yellow-800 dark:text-yellow-400',
    message: 'text-yellow-700 dark:text-yellow-100',
  },
  info: {
    background: 'bg-blue-50 dark:bg-blue-900/20',
    icon: 'text-blue-400 dark:text-blue-600',
    title: 'font-medium text-blue-800 dark:text-blue-400',
    message: 'text-blue-700 dark:text-blue-100',
  },
}

const UIAlert = memo(
  ({
    color: variant = 'default',
    dense = false,
    message,
    title,
    className = '',
    hideIcon = false,
    children,
  }: IUIAlertProps) => {
    const Icon = IconMap[variant as AlertColor]
    const color = colorMap[variant as AlertColor]

    return (
      <div
        className={cn(
          dense ? 'py-1' : 'py-4',
          className,
          'rounded-md px-4',
          color.background,
        )}
      >
        <div className="flex items-start">
          {!hideIcon && (
            <div className="flex-shrink-0">
              <Icon
                className={cn(dense ? 'h4 w-4' : 'h-5 w-5', color.icon)}
                aria-hidden="true"
              />
            </div>
          )}

          <div className={cn(hideIcon ? '' : 'ml-3', color.message, 'w-full')}>
            {children ? (
              children
            ) : (
              <>
                {title && (
                  <h3
                    className={cn(
                      'ui mb-1 pt-0.5 tracking-tight text-sm leading-none',
                      color.title,
                    )}
                  >
                    {title}
                  </h3>
                )}
                {message && (
                  <p className="mb-0 text-small text-inherit font-light">
                    {message}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    )
  },
)

UIAlert.displayName = 'UIAlert'

export default UIAlert
