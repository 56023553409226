import { type UploadCtxProvider } from '@uploadcare/react-uploader'
import { useMemo, useRef } from 'react'

export interface UseUploaderCtxProvider {
  ref?: React.RefObject<InstanceType<UploadCtxProvider>>
}

const useUploaderCtxProvider = ({ ref }: UseUploaderCtxProvider = {}) => {
  const localProviderRef = useRef<InstanceType<UploadCtxProvider>>(null)

  const uploadedFiles = useRef<string[]>([])

  const ctxProviderRef = useMemo(
    () => ref || localProviderRef,
    [ref, localProviderRef],
  )

  const resetUploaderState = () => {
    ctxProviderRef.current?.uploadCollection.clearAll()
    uploadedFiles.current = []
  }

  const addFile = (file: File) => {
    ctxProviderRef.current?.addFileFromObject(file, { silent: false })
  }

  return {
    ctxProviderRef,
    resetUploaderState,
    addFile,
    uploadedFiles,
  }
}

export default useUploaderCtxProvider
