import { useMemo } from 'react'

import { AppFeatures } from '@/models/enums'

import { useGetFeatures } from './queries/features/useGetFeatures'

export const useFeatures = () => {
  const { data: allFeatures, isLoading: isFirstLoading } = useGetFeatures()

  const isThirdPartyIntegrationsEnabled = useMemo(() => {
    return (
      allFeatures &&
      !isFirstLoading &&
      !!allFeatures.data[AppFeatures.ThirdPartyIntegrations]
    )
  }, [allFeatures, isFirstLoading])

  const isAlertsEnabled = useMemo(() => {
    return (
      allFeatures && !isFirstLoading && !!allFeatures.data[AppFeatures.Alerts]
    )
  }, [allFeatures, isFirstLoading])

  return {
    isThirdPartyIntegrationsEnabled,
    isAlertsEnabled,
    isFirstLoading,
  }
}
