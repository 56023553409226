import { usePathname, useRouter } from 'next/navigation'
import React, { PropsWithChildren, useEffect } from 'react'

import { useAuthContext, useCurrentUser } from '@/hooks'

import { Loader } from './Loader'
import PublicLayout from './Public'
import PrivateLayout from './Private'

export const RootLayout = ({ children }: PropsWithChildren) => {
  const {
    session,
    isSigningOut,
    isInitialLogIn,
    isProtectedPage,
    isPublicPage,
  } = useAuthContext()

  const {
    shouldCompleteSignUp,
    isLoading: isLoadingUser,
    isFirstLoad,
    currentUser,
  } = useCurrentUser()

  const router = useRouter()

  const pathname = usePathname()

  const isRootPath = pathname === '/'

  useEffect(() => {
    if (session && shouldCompleteSignUp && pathname !== '/pass-setup') {
      router.push('/registration')
    }
  }, [session, shouldCompleteSignUp, router, pathname])

  if (pathname === '/pass-setup' || pathname === '/pass-reset') {
    return <PublicLayout>{children}</PublicLayout>
  }

  if (!session) {
    return (
      <PublicLayout>
        {isInitialLogIn ||
        isLoadingUser ||
        ((isSigningOut || !isPublicPage(pathname)) && !isRootPath) ? (
          <Loader />
        ) : (
          children
        )}
      </PublicLayout>
    )
  }

  if (
    (isLoadingUser && isFirstLoad) ||
    shouldCompleteSignUp ||
    isProtectedPage(pathname) ||
    pathname === '/login' ||
    !currentUser
  ) {
    return <PublicLayout>{isLoadingUser ? <Loader /> : children}</PublicLayout>
  }

  return <PrivateLayout>{children}</PrivateLayout>
}

RootLayout.displayName = 'RootLayout'
